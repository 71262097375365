import { doc, getDoc, updateDoc } from 'firebase/firestore';
import React, { Component } from 'react';
import { Card, Col, Form, Modal, Row, Button, Offcanvas } from 'react-bootstrap';
import db from '../firebase';
import { LoadProduction } from '../store/MetaStore';

export default class LoadMonitorFrame extends Component {
  constructor(props) {
    super(props);
    this.state = LoadProduction.init;
    this.handleModalStatus = this.handleModalStatus.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.handleSaveStatus = this.handleSaveStatus.bind(this);
    this.handleCloseOffcanvas = this.handleCloseOffcanvas.bind(this);
    this.handleConfirmCloseTicket = this.handleConfirmCloseTicket.bind(this);
  }

  async componentDidMount() {
    const { pathname } = window.location;
    const cleanedPathname = pathname.startsWith('/monitor/') ? pathname.substring('/monitor/'.length) : pathname;
    const monitorState = await doc(db, '$:__site-monitor', cleanedPathname);
    this.setState({ nameSite: cleanedPathname });

    if (cleanedPathname !== '') {
      const isLicenseAvalible = await getDoc(monitorState);
      const data = isLicenseAvalible.data()
      if (isLicenseAvalible.exists()) {
        this.setState({
          ...data,
          pageExist: true,
          secureState: false,
        });
      } else {
        setTimeout(() => {
          alert('este sitio no existe');
          window.location = '/';
        }, 1500);
      }
    }
    console.log('load monitor');
  }

  getStatusColor(status) {
    switch (status.toLowerCase()) {
      case 'pendiente a pago':
        return 'yellow';
      case 'cancelado':
        return 'red';
      case 'error':
        return 'red';
      case 'pagado':
        return 'green';
      case 'completado':
        return 'blue';
      case 'preparando':
        return 'orange';
      default:
        return 'black';
    }
  }

  handleModalStatus(ticket) {
    this.setState({
      modalStatusTicket: true,
      selectedTicket: ticket,
      pointStatusTicket: ticket.status,
    })
  }

  handleCloseModal() {
    this.setState({
      modalStatusTicket: false,
      selectedTicket: null,
      pointStatusTicket: '',
      frameConfirmClose: false,
    });
  };

  handleChangeStatus(event) {
    const newStatus = event.target.value;
    const ticketId = this.state.selectedTicket.idTicket;
    const currentDateTime = new Date().toLocaleString();

    this.setState(prevState => {
      const updatedLog = [
        ...prevState.pointItemLog,
        {
          ticketId: ticketId,
          status: newStatus,
          date: currentDateTime
        }
      ];

      return {
        pointStatusTicket: newStatus,
        pointItemLog: updatedLog,
      };
    });

    if (event.target.value === "cerrar") {
      this.setState({
        secureState: true,
        frameConfirmClose: true,
      });
      console.log('cerrar');
    }
  }


  handleCloseOffcanvas() {
    this.setState({
      frameConfirmClose: false,
      modalStatusTicket: false,
      secureState: false,
    });
  }

  handleConfirmCloseTicket() {
    const ticketId = this.state.selectedTicket.idTicket;
    const ticketToClose = this.state.pointMonitor.ticketsNow.find(ticket => ticket.idTicket === ticketId);
    const currentDateTime = new Date().toLocaleString();

    if (ticketToClose) {
      const updatedTicketsNow = this.state.pointMonitor.ticketsNow.filter(ticket => ticket.idTicket !== ticketId);
      const updatedTicketsCC = [...this.state.pointMonitor.aTicketsCC, ticketToClose];

      const updatedLog = [
        ...this.state.pointItemLog,
        {
          ticketId: ticketId,
          status: 'cerrar',
          date: currentDateTime
        }
      ];

      this.setState(prevState => ({
        pointMonitor: {
          ...prevState.pointMonitor,
          ticketsNow: updatedTicketsNow,
          aTicketsCC: updatedTicketsCC,
        },
        pointItemLog: updatedLog,
        frameConfirmClose: false,
        modalStatusTicket: false,
        secureState: false,
      }), async () => {
        await updateDoc(doc(db, '$:__site-monitor', this.state.siteName), this.state);
        console.log(this.state)
      });
    }
  }


  handleSaveStatus = () => {
    const { selectedTicket, pointStatusTicket } = this.state;

    selectedTicket.status = pointStatusTicket;

    this.setState({ modalStatusTicket: false, selectedTicket: null, pointStatusTicket: '' },
      async ()=>{
      await updateDoc(doc(db, '$:__site-monitor', this.state.siteName), this.state);
      console.log(this.state)
    });
    
  };

  render() {
    const { pointMonitor } = this.state;
    console.log(this.state);

    if (!this.state.pageExist) {
      return <div>Cargando...</div>;
    }

    return (
      <div className='App-header'>
        <div className='container'>
          <Row>
            {pointMonitor.ticketsNow && pointMonitor.ticketsNow.length > 0 ? (
              pointMonitor.ticketsNow.map((ticket, k) => {
                let ticketData;

                if (Array.isArray(ticket.data[Object.keys(ticket.data)[0]])) {
                  console.log(ticket.data[Object.keys(ticket.data)[0]])
                  ticketData = ticket.data[Object.keys(ticket.data)[0]].map((item, index) => (
                    <div key={`${item.name}-${index}`} style={{ fontSize: '0.48em' }}>
                      <strong>Nombre:</strong> {item.name}<br />
                      <strong>Cuanto:</strong> {item.cuantos}<br />
                      <strong>De:</strong>
                      <ul>
                        {item.keyItems && item.keyItems.map((keyItem) => (
                          <li key={`${keyItem.keyItem}-${keyItem.clicks}`}>{keyItem.keyItem}: {keyItem.clicks}</li>
                        ))}
                      </ul>
                    </div>
                  ));
                } else {
                  console.log(ticket.data[Object.keys(ticket.data)[0]])
                  const dataObj = ticket.data[Object.keys(ticket.data)[0]];
                  ticketData = (
                    <div style={{ fontSize: '0.48em' }}>
                      <strong>Nombre:</strong> {dataObj.name}<br />
                      <strong>Cantidad:</strong> {dataObj.clicks}<br />
                      <strong>Precio:</strong> {dataObj.priceItem}<br />
                    </div>
                  );
                }

                return (
                  <Col key={k} md={4}>
                    <Card className="mb-3" onClick={() => this.handleModalStatus(ticket)}>
                      <Card.Body>
                        <Card.Title>{ticket.nameId}</Card.Title>
                        {ticketData}
                        <Card.Text>
                          <strong>Total Cost:</strong> {ticket.totalCost}
                        </Card.Text>
                        <Card.Text>
                          <strong>Status:</strong>
                          <span style={{ color: this.getStatusColor(ticket.status) }}> {ticket.status}</span>
                        </Card.Text>
                        <Card.Footer>
                          <small className="text-muted">ID: {ticket.idTicket}</small>
                        </Card.Footer>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              })
            ) : (
              <div>No hay tickets disponibles</div>
            )}
          </Row>
          <Offcanvas show={this.state.frameConfirmClose} onHide={this.handleCloseOffcanvas} placement="bottom">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Confirmar Cierre de Ticket</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <p>¿Estás seguro de que deseas cerrar este ticket?</p>
              <Button variant="danger" onClick={this.handleConfirmCloseTicket}>
                Cerrar Ticket
              </Button>
              <Button variant="secondary" onClick={this.handleCloseOffcanvas}>
                Cancelar
              </Button>
            </Offcanvas.Body>
          </Offcanvas>

          <Modal show={this.state.modalStatusTicket} onHide={this.handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Cambiar Status</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group controlId="formStatusSelect">
                <Form.Label>Seleccione el nuevo status</Form.Label>
                <Form.Control
                  as="select"
                  value={this.state.pointStatusTicket}
                  onChange={this.handleChangeStatus}
                >
                  <option value=""></option>
                  <option value="preparando">Preparando</option>
                  <option value="pagado">Pagado</option>
                  <option value="cancelado">Cancelado</option>
                  <option value="cerrar">Cerrar</option>
                </Form.Control>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleCloseModal}>
                Cerrar
              </Button>
              <Button variant="primary" disabled={this.state.secureState} onClick={this.handleSaveStatus}>
                Guardar Cambios
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
}
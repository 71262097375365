import React, { Component } from 'react';
import UserTicket from './Common/UserTicket';
import { Button } from 'react-bootstrap';

class LoadingSimulator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: [
                "Instalando app",
                "Creando rutas",
                "Creando certificados de seguridad",
                "Instalando modulos de seguridad",
                "Instalando direccionamiento",
                "Asignando cache",
                "Creando base de datos",
                "Espere...",
                "Espere...",
                "Listo!!!"
            ],
            currentMessageIndex: 0,
            progress: 0
        };
    }

 
    componentDidMount() {
        this.interval = setInterval(this.updateProgress, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    updateProgress = () => {
        const { currentMessageIndex, messages } = this.state;
        if (currentMessageIndex < messages.length - 1) {
            this.setState(prevState => ({
                currentMessageIndex: prevState.currentMessageIndex + 1,
                progress: ((prevState.currentMessageIndex + 1) / (messages.length - 1)) * 100
            }));
        } else {
            clearInterval(this.interval);
        }
    };

    render() {
        const { messages, currentMessageIndex, progress } = this.state;

        return (
            <>
             
                    <div className="loading-container">
                        <div className="loading-bar txt-center" style={{ width: `${progress}%` }}></div>
                        <p className="loading-text">{messages[currentMessageIndex]}</p>
                    </div> 
            </>
        );
    }
}

export default LoadingSimulator;
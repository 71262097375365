import React, { Component } from 'react'

export default class Tutoriales extends Component {
  render() {
    return (
      <div>Tutoriales
        
        <p>
            
        </p>
        <br/>
        <p>
            
        </p>
      </div>
    )
  }
}

import React, { Component } from 'react'
import Alert from 'react-bootstrap/Alert';

export default class AlertMsn extends Component {

  constructor(props) {
    super(props)
    this.state = this.props.config
  }


  render() {

    return (
      <Alert show={this.props.modalAlert}
        variant={this.props.alertState}
        onClose={this.props.handleCloseAlert}
        dismissible
        className='position-absolute z-25'
      >
        <Alert.Heading>{this.props.txtTitleAlert}</Alert.Heading>
        <p>
          {this.props.txtMainAlert}
        </p>
      </Alert>
    )
  }
}

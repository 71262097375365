import React, { Component } from 'react'
import { LoadApp } from '../store/MetaStore'
import { Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, FloatingLabel, Form, FormCheck, FormControl, InputGroup, Modal, Row, Table } from 'react-bootstrap'
import { CiGrid2H, CiGrid2V, CiGrid41 } from "react-icons/ci";
import LoadingSimulator from './LoadingSimulator';
import { setDoc, doc, getDoc } from 'firebase/firestore';
import db, { storage } from '../firebase';
import UserTicket from './Common/UserTicket';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';


export default class AdminStyleMain extends Component {

    constructor(props) {
        super(props)
        this.state = LoadApp.init
        this.state.nextInsideFrame = false

        this.state.rowStyleMld = {
            height: '284px',
            width: '330px',
            overflow: 'auto',
            flexWrap: 'nowrap',
            alignItems: 'center',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        };
    }



    componentDidMount() {

    }


    nextStep() {
        this.setState({ nextInsideFrame: true })
        document.getElementsByClassName('form-txt-sgm')[0].value = ''
        console.log( document.getElementsByClassName('form-txt-sgm'))
    }

    handleInputChange = (event) => {
        const newValue = event.target.value;
        this.setState((prevState) => {
            const updatedBtnTxtTitle = [...prevState.btnTxtTitle];
            updatedBtnTxtTitle[6] = newValue;
            return { btnTxtTitle: updatedBtnTxtTitle };
        });
    }

    toggleCheck = (checkId) => {
        this.setState((prevState) => ({
            selectedCheck: prevState.selectedCheck === checkId ? null : checkId
        }));
    }

    midleStep() {
        this.setState({ lastStep: true })
    }

    async finishStep() {
        const { nameTempItem, descriptionTempItem, itemsMap, tags } = this.state;

        const newItem = {
            name: 'no-item',
            attribute: descriptionTempItem,
            charas: this.state,
            src: this.state.noImg,
            itemTags: this.state.tags,
            priceItem: 0,
        };

        // Convertir itemsMap a un objeto plano
        const updatedItemsMap = { ...itemsMap, [nameTempItem]: newItem };

        console.log(this.state)
        console.log(this.state.itemTags)
        this.setState({
            utaStep: true,
            tags: [...tags, { name: 'pointItemSubName', value: 0 }],
            itemTags: tags,
            modalWelcome: true,
        })

        
        const monitor = {
            ticketsNow: [],
            aTicketsCC: [],
            aTicketsSS: [],
        }


        await setDoc(doc(db, '$:__sites-name', this.state.siteName), this.state);
        await setDoc(doc(db, '$:__site-monitor', this.state.siteName), monitor);

    } 


    handleExportPDF = () => {

        UserTicket(this.state);

        setTimeout(() => {
            window.location = '/admin/' + this.state.siteName
        }, 1200);

    };

    async handleUrlTitle() {
        const loadResurses = () => {
            try {
                getDownloadURL(ref(storage, 'items/Thumbs/' + this.state.sscid + '/' + '/logo.jpg'))
                    .then((url) => {
                        this.setState({                            
                            logoImg: url,
                        })
                    }).catch((error) => {
                        console.log(error)
                    })
            } catch (e) {
                console.log(e)
            }
        }
        loadResurses()
    }

    async handleTitleImg(c) {        
        let fileData = c.target.files[0]
        const storageRef = ref(storage, 'items/Thumbs/' + this.state.sscid + '/logo.jpg');
        uploadBytes(storageRef, fileData).then((snapshot) => {
            this.handleUrlTitle()            
        })
    }

    crossBtnItemStore() {
        let putImg = document.querySelectorAll('#itemImg')
        putImg[0].click()
    }



    render() {
        return (
            <div>
                <Card className='card-width'>
                    <CardTitle></CardTitle>
                    <CardHeader>
                        {this.state.nextInsideFrame === false ?
                            <CardTitle>{this.state.txtTitle[1]}</CardTitle>
                            : this.state.lastStep === false ?
                                <CardTitle>{this.state.txtTitle[2]}</CardTitle> :
                                <CardTitle>{this.state.txtTitle[3]}</CardTitle>}
                    </CardHeader>
                    {this.state.nextInsideFrame === false ? <CardBody>
                        <Row>
                            <Col>
                                <Card style={this.state.choiseColorBackground}>
                                    <CardBody>
                                        <div className={`frame-left-sample ${this.state.choiseStyleMain}`}>
                                            <Button className={`${this.state.choiseBtnColor}`}>{this.state.btnTxtTitle[6]}</Button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col>
                                <Card className='change-main-a'>
                                    <CardBody>
                                        <InputGroup>
                                            <FormControl
                                                id='txt-welcome'
                                                className='form-txt-sgm'
                                                placeholder='Cambiar Texto de bienvenida'
                                                onChange={this.handleInputChange}
                                            />
                                        </InputGroup>
                                    </CardBody>
                                </Card>

                                <Card className='change-main-a'>
                                    <CardTitle><h6>Cambiar Color de boton</h6></CardTitle>
                                    <CardBody>
                                        < div style={{ display: 'inline-flex', cursor: 'pointer' }}>
                                            <div onClick={() => { this.setState({ choiseBtnColor: 'style-btn-a' }) }} className='style-divbtn-a'></div>
                                            <div onClick={() => { this.setState({ choiseBtnColor: 'style-btn-b' }) }} className='style-divbtn-b'></div>
                                            <div onClick={() => { this.setState({ choiseBtnColor: 'style-btn-c' }) }} className='style-divbtn-c'></div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <div className='c-items' >
                            <Form className='w-270' >
                                {['radio'].map((type) => (
                                    <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check
                                            inline
                                            label="1"
                                            name="group1"
                                            type={type}
                                            id={`inline-${type}-1`}
                                            defaultChecked={false}
                                            onChange={() => { this.setState({ choiseStyleMain: 'b-items' }) }}
                                        />
                                        <Form.Check
                                            inline
                                            label="2"
                                            name="group1"
                                            type={type}
                                            id={`inline-${type}-2`}
                                            defaultChecked={true}
                                            style={{ marginLeft: '5rem' }}
                                            onChange={() => { this.setState({ choiseStyleMain: 'c-items' }) }}
                                        />
                                    </div>
                                ))}
                            </Form>
                        </div>
                        <CardTitle>Area de fondo de pantalla</CardTitle>
                        <CardBody>
                            <div style={{ display: 'inline-flex', cursor: 'pointer' }}>
                                <div onClick={() => { this.setState({ choiseColorBackground: 'style-main-a' }) }} style={{ margin: '0px 15px 0px 0px', width: '25px', height: '25px', backgroundColor: 'skyblue', borderStyle: 'solid' }}></div>
                                <div onClick={() => { this.setState({ choiseColorBackground: 'style-main-b' }) }} style={{ margin: '0px 15px 0px 0px', width: '25px', height: '25px', backgroundColor: 'pink', borderStyle: 'solid' }}></div>
                                <div onClick={() => { this.setState({ choiseColorBackground: 'style-main-c' }) }} style={{ width: '25px', height: '25px', backgroundColor: 'blue', borderStyle: 'solid' }}></div>
                            </div>
                        </CardBody>
                    </CardBody> : this.state.lastStep === false ?
                        <CardBody>
                            <Row>
                                <Col>
                                    <Card className={this.state.cardBackground}>
                                        <CardBody>
                                            <InputGroup>
                                                <FormControl
                                                    id='txt-traker'
                                                    placeholder={this.state.txtTraker}
                                                />
                                            </InputGroup>
                                        </CardBody>
                                        <CardBody>
                                            <div className="check-container">
                                                <Form.Check
                                                    type="checkbox"
                                                    label="Individual"
                                                    checked={this.state.selectedCheck === 1}
                                                    onChange={() => this.toggleCheck(1)}
                                                />
                                            </div>
                                            <div className="check-container">
                                                <Form.Check
                                                    type="checkbox"
                                                    label="Separado"
                                                    checked={this.state.selectedCheck === 2}
                                                    onChange={() => this.toggleCheck(2)}
                                                />
                                            </div>
                                        </CardBody>
                                        <CardBody>
                                            <Row style={this.state.rowStyleMld}>
                                                <Col xs={this.state.size_col} key={"das1"}>
                                                    <div className="glass-effect" key={"dsae"}>
                                                        <img className='img-card' alt='no-img.png' width={'100%'} />
                                                    </div>
                                                </Col>
                                                <Col xs={this.state.size_col} key={"das13"}>
                                                    <div className="glass-effect" key={"dsa1"}>
                                                        <img className='img-card' alt='no-img.png' width={'100%'} />
                                                    </div>
                                                </Col>
                                                <Col xs={this.state.size_col} key={"das12"}>
                                                    <div className="glass-effect" key={"dsa4"}>
                                                        <img className='img-card' alt='no-img.png' width={'100%'} />
                                                    </div>
                                                </Col>
                                                <Col xs={this.state.size_col} key={"das1s2"}>
                                                    <div className="glass-effect" key={"dsca4"}>
                                                        <img className='img-card' alt='no-img.png' width={'100%'} />
                                                    </div>
                                                </Col>
                                                <Col xs={this.state.size_col} key={"dasa12"}>
                                                    <div className="glass-effect" key={"dsqa4"}>
                                                        <img className='img-card' alt='no-img.png' width={'100%'} />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                        <CardBody>
                                            <Button >{this.state.btnToEnlist}</Button>
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col>
                                    <Card className='change-main-a'>
                                        <CardBody>
                                            <InputGroup>
                                                <FormControl                                                   
                                                    id='txt-seg'
                                                    placeholder='Cambiar Texto de seguimiento'
                                                    onChange={(e) => { this.setState({ txtTraker: e.target.value }) }}
                                                />
                                            </InputGroup>
                                        </CardBody>
                                    </Card>

                                    <Card className='change-main-a'>
                                        <CardTitle><h6>Cambiar tipo de menu</h6></CardTitle>
                                        <CardBody>
                                            < div style={{ display: 'inline-flex', cursor: 'pointer' }}>
                                                <div onClick={() => {
                                                    this.setState({
                                                        size_col: 3, rowStyleMld: {
                                                            height: '284px',
                                                            width: '330px',
                                                            overflow: 'auto',
                                                            flexWrap: 'nowrap',
                                                            alignItems: 'center',
                                                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                                        }
                                                    })
                                                }} ><CiGrid2V /></div>
                                                <div onClick={() => { this.setState({ size_col: 6 }) }} ><CiGrid41 /></div>
                                                <div onClick={() => {
                                                    this.setState({
                                                        size_col: 12, rowStyleMld: {
                                                            height: '284px',
                                                            width: '330px',
                                                            overflow: 'auto',
                                                            flexWrap: 'wrap',
                                                            alignItems: 'center',
                                                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                                        }
                                                    })
                                                }} ><CiGrid2H /></div>
                                            </div>
                                        </CardBody>
                                        <CardBody>
                                            <InputGroup>
                                                <FormControl
                                                    placeholder='Cambiar el texto de Pedido'
                                                    onChange={(e) => this.setState({ btnToEnlist: e.target.value })}
                                                />
                                            </InputGroup>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </CardBody> : this.state.utaStep === false ? <CardBody>
                            <Row>
                                <CardBody>
                                    <InputGroup >
                                        <FloatingLabel
                                            controlId="floatingSelectGrid"
                                            label="misistemita.store/"
                                        >
                                            <FormControl
                                                className='h-80'
                                                placeholder='Nombre de tu app'
                                                onChange={(e) => { this.setState({ siteName: e.target.value.toLowerCase() }) }}
                                            /></FloatingLabel>
                                    </InputGroup>
                                </CardBody>
                                <CardBody>
                                    <InputGroup >
                                        <FormControl
                                            className='h-80'
                                            placeholder='Contraseña de Admin'
                                            type='password'
                                            onChange={(e) => { this.setState({ txtUserPass: e.target.value }) }}
                                        />
                                    </InputGroup>
                                </CardBody>
                                <hr />
                                <CardBody>
                                    <CardHeader>Cargar Logo</CardHeader>
                                    <img src={this.state.logoImg} width={'33%'} onClick={() => { this.crossBtnItemStore() }} />

                                    <Form.Control
                                        style={{ display: 'none' }}
                                        id='itemImg'
                                        type='file'
                                        accept='image/png,image/jpeg'
                                        onChange={(e) => this.handleTitleImg(e)}
                                    />
                                </CardBody>
                                <CardBody>
                                    <CardHeader>Enlaces</CardHeader>
                                    <CardTitle>Panel de administrador</CardTitle>
                                    <p>misistemita.store/admin/{this.state.siteName}</p>
                                    <CardTitle>Monitor</CardTitle>
                                    <p>misistemita.store/monitor/{this.state.siteName}</p>
                                    <CardTitle>Pagina principal</CardTitle>
                                    <p>misistemita.store/{this.state.siteName}</p>
                                </CardBody>
                            </Row>
                        </CardBody> : <><LoadingSimulator config={this.state} />
                            <br />
                            <Button onClick={() => { this.handleExportPDF() }} variant='success'>Guardar Datos</Button>
                        </>}
                    <CardFooter>
                        {this.state.nextInsideFrame === false ?
                            <Button onClick={() => { this.nextStep() }}>{this.state.btnTxtTitle[1]}</Button>
                            : this.state.lastStep === false ?
                                <Button onClick={() => { this.midleStep() }}>{this.state.btnTxtTitle[5]}</Button> :
                                this.state.utaStep === false ? <Button onClick={() => { this.finishStep() }}>{this.state.btnTxtTitle[7]}</Button>
                                    : <></>
                        }</CardFooter>
                </Card>
            </div>
        )
    }
}

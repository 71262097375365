import React, { Component } from 'react'
import { LoadProduction } from '../store/MetaStore'
import { collection, doc, getDoc } from 'firebase/firestore';
import db from '../firebase';
import { Button, Card, CardBody, CardFooter, CardHeader, CardTitle, FormControl, InputGroup } from 'react-bootstrap'

export default class StartPage extends Component {

    constructor(props) {
        super(props)
        this.state = LoadProduction.init;
    }

    async nextStep() {      
        if (this.state.keyLicense !== '') {
         const license = await doc(db, '$:__site-key', this.state.keyLicense)
            const isLicenseAvalible = await getDoc(license)            
            try {
                console.log(isLicenseAvalible)
                if (isLicenseAvalible.exists() === true) {
                    this.props.nextFrame(false)
                }
            } catch (e) {
                console.log(e)
                alert('El codigo no es valido !')
            }
        }
    }


    render() {
        return (
            <div>
                <Card>
                    <CardHeader>
                        <CardTitle>{this.state.txtTitle[0]}</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <InputGroup>
                            <FormControl
                                placeholder='Ingresa el codigo de activacion'
                                type='password'
                                onChange={(e) => {
                                    this.setState({
                                        keyLicense: e.target.value
                                    })
                                }}
                            />
                        </InputGroup>
                    </CardBody>
                    <CardFooter>
                        <Button onClick={() => { this.nextStep() }}>{this.state.btnTxtTitle[1]}</Button>
                        <br />
                        <p className='f-s-1 c-pointer m-t-1' onClick={() => { window.location = '/' }}>Adquirir licencia</p>
                    </CardFooter>
                </Card>
            </div>
        )
    }
}

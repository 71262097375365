import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js'; // Importa todos los componentes de Chart.js
import { doc, getDoc } from 'firebase/firestore';
import db from '../../firebase';

// Registra todos los componentes de Chart.js
Chart.register(...registerables);

export default class FrameCharts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagados: 0,
      cancelados: 0,
    };
  }

  async componentDidMount() {
    const { pathname } = window.location;
    const cleanedPathname = pathname.startsWith('/admin/')
      ? pathname.substring('/admin/'.length)
      : pathname;

    const monitorStateRef = doc(db, '$:__site-monitor', cleanedPathname);
    const monitorStateSnapshot = await getDoc(monitorStateRef);

    if (monitorStateSnapshot.exists()) {
      const monitorData = monitorStateSnapshot.data();
      const ticketsCC = monitorData.pointMonitor?.aTicketsCC || [];

      let pagados = 0;
      let cancelados = 0;

      ticketsCC.forEach((ticket) => {
        if (ticket.status === 'pagado') {
          pagados++;
        } else if (ticket.status === 'cancelado') {
          cancelados++;
        }
      });

      this.setState({ pagados, cancelados });
    } else {
      console.error('No se encontró el documento en Firebase.');
    }
  }

  render() {
    const { pagados, cancelados } = this.state;

    const data = {
      labels: ['Pagados', 'Cancelados'],
      datasets: [
        {
          label: 'Tickets',
          data: [pagados, cancelados],
          backgroundColor: ['green', 'red'],
        },
      ],
    };

    const options = {
      responsive: true,
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };

    return (
      <div>
        <h3>Gráfico de Tickets</h3>
        <Bar data={data} options={options} />
      </div>
    );
  }
}

import React, { Component } from 'react'
import { LoadApps } from '../store/MetaStore'
import { Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Form, FormControl, InputGroup, Row } from 'react-bootstrap';
import { FaCircleChevronLeft } from 'react-icons/fa6';
import { Firestore, collection, doc, getDoc, getDocs, setDoc, updateDoc } from 'firebase/firestore'
import { getDownloadURL, ref, uploadBytes, uploadBytesResumable } from 'firebase/storage'
import db, { storage } from '../firebase';
import AlertMsn from './Common/AlertMsn';

export default class FrameAdmin extends Component {
    constructor(props) {
        super(props)
        this.state = this.props.config
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
    }

    handleBackButtonClick = () => {
        if (this.props.onCrossBtn) {
            this.props.onCrossBtn();
        } else {
            console.error("E-701:Sistema crusado en falla");
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.state.modalAlert && !prevState.modalAlert) {
            this.autoCloseTimer = setTimeout(() => {
                this.handleCloseAlert();
            }, 1450);
        }

        if (!this.state.modalAlert && prevState.modalAlert) {
            clearTimeout(this.autoCloseTimer);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.autoCloseTimer);
    }

    async handleNewItem() {
        // Definir qué partes del estado NO deben ser enviadas al servidor

        // Primero actualiza el estado local para asegurarte de que el modal esté en `false`
        this.setState({
            modalAlert: false,
            displayCharts: 'none',
            choiseColorBackground: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',

                backgroundImage: `linear-gradient(to ${this.state.pointGradientDirection}, ${this.state.pointColorAlpha}, ${this.state.pointColorBetha})`
            }
        });

        const { displayCharts, modalAlert, txtMainAlert, txtTitleAlert, alertState, ...dataToSave } = this.state;

        try {
            // Guardar en el servidor sin incluir modalAlert ni otras propiedades no necesarias
            await updateDoc(doc(db, '$:__sites-name', this.state.siteName), dataToSave);
            console.log('Datos guardados:', dataToSave);

            // Después de guardar, mostrar el modal de éxito
            this.setState({
                modalAlert: true,
                txtMainAlert: 'Configuración emparejada con el servidor',
                txtTitleAlert: 'Mi sistemita',
                alertState: 'success',
            });
        } catch (e) {
            // Si ocurre un error, mostrar el modal de error
            this.setState({
                modalAlert: true,
                txtMainAlert: 'Error al emparejar con el servidor',
                txtTitleAlert: 'Mi sistemita',
                alertState: 'danger',
            });
        }
    }

    handleDirectionChange = (e) => {
        this.setState({ pointGradientDirection: e.target.value });
    }

    handleColor1Change = (e) => {
        this.setState({ pointColorAlpha: e.target.value });
    }

    handleColor2Change = (e) => {
        this.setState({ pointColorBetha: e.target.value });
    }


    handleInputChange = (event) => {
        const newValue = event.target.value;
        this.setState((prevState) => {
            const updatedBtnTxtTitle = [...prevState.btnTxtTitle];
            updatedBtnTxtTitle[6] = newValue;
            return { btnTxtTitle: updatedBtnTxtTitle };
        });
    }

    handleCloseAlert() {
        this.setState({ modalAlert: false });
    };

    render() {
        const choiseColorBackground = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundImage: `linear-gradient(to ${this.state.pointGradientDirection}, ${this.state.pointColorAlpha}, ${this.state.pointColorBetha})`
        };
        return (
            <div>
                <AlertMsn
                    modalAlert={this.state.modalAlert}
                    alertState={this.state.alertState}
                    txtTitleAlert={this.state.txtTitleAlert}
                    txtMainAlert={this.state.txtMainAlert}
                    handleCloseAlert={this.handleCloseAlert}
                />
                <Card>
                    <CardBody>
                        < FaCircleChevronLeft className=' z-10' onClick={() => { this.handleBackButtonClick() }} />
                        <CardHeader>
                            {this.state.nextInsideFrame === false ?
                                <CardTitle>{this.state.txtTitle[1]}</CardTitle>
                                : this.state.lastStep === false ?
                                    <CardTitle>{this.state.txtTitle[2]}</CardTitle> :
                                    <CardTitle>{this.state.txtTitle[3]}</CardTitle>}
                        </CardHeader>
                        <Row>
                            <Col>
                                <Card style={choiseColorBackground} >
                                    <CardBody>
                                        <div className={`frame-left-sample ${this.state.choiseStyleMain}`}>
                                            <Button className={`${this.state.choiseBtnColor}`}>{this.state.btnTxtTitle[6]}</Button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col>
                                <Card className='change-main-a'>
                                    <CardBody>
                                        <InputGroup>
                                            <FormControl
                                                placeholder='Cambiar Texto de bienvenida'
                                                onChange={this.handleInputChange}
                                            />
                                        </InputGroup>
                                    </CardBody>
                                </Card>

                                <Card className='change-main-a'>
                                    <CardTitle><h6>Cambiar Color de boton</h6></CardTitle>
                                    <CardBody>
                                        < div style={{ display: 'inline-flex', cursor: 'pointer' }}>
                                            <div onClick={() => { this.setState({ choiseBtnColor: 'style-btn-a' }) }} className='style-divbtn-a'></div>
                                            <div onClick={() => { this.setState({ choiseBtnColor: 'style-btn-b' }) }} className='style-divbtn-b'></div>
                                            <div onClick={() => { this.setState({ choiseBtnColor: 'style-btn-c' }) }} className='style-divbtn-c'></div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <div className='c-items' >
                            <Form className='w-270' >
                                {['radio'].map((type) => (
                                    <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check
                                            inline
                                            label="1"
                                            name="group1"
                                            type={type}
                                            id={`inline-${type}-1`}
                                            defaultChecked={false}
                                            onChange={() => { this.setState({ choiseStyleMain: 'b-items' }) }}
                                        />
                                        <Form.Check
                                            inline
                                            label="2"
                                            name="group1"
                                            type={type}
                                            id={`inline-${type}-2`}
                                            defaultChecked={true}
                                            style={{ marginLeft: '5rem' }}
                                            onChange={() => { this.setState({ choiseStyleMain: 'c-items' }) }}
                                        />
                                    </div>
                                ))}
                            </Form>
                        </div>
                        <CardTitle>Area de fondo de pantalla</CardTitle>
                        <CardBody>
                            <div>
                                <label>
                                    Dirección del Gradiente:
                                    <select value={this.state.pointGradientDirection} onChange={this.handleDirectionChange}>
                                        <option value="top">Top</option>
                                        <option value="bottom">Bottom</option>
                                        <option value="left">Left</option>
                                        <option value="right">Right</option>
                                    </select>
                                </label>
                            </div>

                            <div>
                                <label>
                                    Color 1:
                                    <input
                                        type="color"
                                        value={this.state.pointColorAlpha}
                                        onChange={this.handleColor1Change}
                                    />
                                </label>
                            </div>

                            <div>
                                <label>
                                    Color 2:
                                    <input
                                        type="color"
                                        value={this.state.pointColorBetha}
                                        onChange={this.handleColor2Change}
                                    />
                                </label>
                            </div>
                        </CardBody>

                    </CardBody>
                    <CardFooter>
                        <Button onClick={() => { this.handleNewItem() }}>Aplicar Cambios </Button>
                    </CardFooter>
                </Card>
            </div>
        )
    }
}
